<template>
  <div class="page-container">
    <div class="banner">
      <img class="img" src="../../../../assets/blog/banner.png" alt="" />
      <div class="banner-title">
        <h2>{{t("company.blog.banner_title")}}</h2>
      </div>
    </div>
    <section class="info-container">
      <div class="info-box">
        <div class="d-img">
          <img src="" alt="">
        </div>
        <div class="d-text">
          <div class="d-date">Jan 26 .2022</div>
          <div class="d-title">Massive Data</div>
          <div class="d-content">Combine with customer data platform and data development platform (CDP) to easily and efficiently process and transfer massive amounts of data and operate AI model computing tasks in parallel.</div>
          <div class="d-btn"><a href="">Read more</a></div>
        </div>
      </div>
    </section>

    <HelpForm/>
  </div>
</template>
<script>
import { useI18n } from "vue-i18n";
import HelpForm from '../../../../components/HelpForm/index.vue';
export default {
    name: 'BLOG',
    components: {HelpForm},
    setup() {
        const { t } = useI18n();
        return {
          t,
        };
    },
}
</script>
<style lang="less" scoped>
.page-container {
  .banner {
    display: block;
    position: relative;
    z-index: 0;
    background: #000;
    overflow: hidden;
    height: 475px;
    width: 100%;
    .img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .banner-title {
      color: #F4F5F5;
      font-weight: 400;
      font-size: 60px;
      position: absolute;
      top: 50%;
      left: 22%;
      transform: translateY(-50%);
      &::after {
        content: '';
        display: block;
        width: 140px;
        margin: 15px 0;
        border-bottom: 8px solid #FF9333;
        border-radius: 4px;
      }
    }
  }

  .info-container {
    min-height: 500px;
    padding: 80px 0;
    .info-box {
      width: 1140px;
      height: 400px;
      margin: 40px auto;
      box-shadow: 0px 0px 20px 0px rgba(16, 81, 170, 0.1);
      display: flex;
      .d-img {
        // flex: 1;
        width: 520px;
        background: #eee;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      .d-text {
        // flex: 1;
        position: relative;
        width: 620px;
        padding: 50px 40px;
        .d-date {
          color: #999;
          font-size: 14px;
          margin-bottom: 20px;
        }
        .d-title {
          color: #FF9333;
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 20px;
        }
        .d-content {
          color: #333;
          font-size: 14px;
        }
        .d-btn {
          position: absolute;
          bottom: 20px;
          a {
            color: #FF9333;
            padding: 5px 0;
            border-bottom: 1px solid #FF9333;
          }
        }
      }
    }
  }
}
</style>
